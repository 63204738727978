<template>
  <v-container
    id="historic-view"
    fluid
  >
    <v-row
      no-gutters
      justify="center"
    >
      <v-col
        cols="12"
        lg="10"
      >
        <chart-tools
          v-if="showChart"
          :chart-initial-data="chartInitialData"
          :local-bus="localBus"
          :is-preview="true"
          :is-historic="true"
          class="mb-1"
        />
      </v-col>
      <v-col
        cols="12"
        lg="10"
      >
        <v-container class="py-0">
          <v-alert
            v-show="showStatusMessage"
            :type="statusMessageType"
            class="mb-4"
          >
            <span v-html="statusMessage" />
            <ul v-if="emptyDatasets.length > 0">
              <li
                v-for="set in emptyDatasets"
                :key="set"
              >
                {{ set }}
              </li>
            </ul>
          </v-alert>
        </v-container>
      </v-col>
      <v-col
        cols="12"
        lg="10"
      >
        <material-chart-js-card
          v-if="showChart"
          is-dialog="true"
          :local-bus="localBus"
          :chart-initial-data="chartInitialData"
          :is-historic="true"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import store from '@/store'
  import Vue from 'vue'

  export default {
    name: 'HistoricData',

    data: () => ({

      chartInitialData: {},
      localBus: new Vue(),
      showChart: true,

      showStatusMessage: false,
      statusMessageType: 'info',
      statusMessage: '',
      statusMesssageNoDatasets: 'There is no chart data to display. Please either select a chart from your dashboard, or select data points on your equipment, to navigate to this page and display data.',
      statusMessageEmptyDatasets: 'One or more of the datasets were not available in this range:',
      alert404Error: 'We were unable to find data in the given range.',
      alertGeneralError: 'We were unable to complete your request at this time. Please try again later.',
      alertAverageInfo: 'The data points shown are averaged to improve performance.',
      emptyDatasets: [],
      totalDatasets: 0,

    }),

    created () {
      this.chartInitialData = store.get('app/historicData').datapoints
      if (!this.chartInitialData || !this.chartInitialData.datasets) {
        this.showStatusMessage = true
        this.showChart = false
        this.statusMessageType = 'info'
        this.statusMessage = this.statusMesssageNoDatasets
      }

      this.localBus.$on('getByDateRangeError', (error) => {
        this.showStatusMessage = true
        this.statusMessageType = 'error'
        if (error === 404) {
          this.statusMessage = this.alert404Error
        } else {
          this.statusMessage = this.alertGeneralError
        }
      })

      this.localBus.$on('getByDateRangeSuccess', () => {
        this.loadingHistoricData = false
      })

      this.localBus.$on('getByDateRangePartialSuccess', (emptyDatasets, totalDatasets) => {
        this.emptyDatasets = emptyDatasets
        this.totalDatasets = totalDatasets
        this.showStatusMessage = true
        this.statusMessageType = 'info'
        this.statusMessage = this.statusMessageEmptyDatasets
      })

      this.localBus.$on('setChartHistoryData', () => {
        this.emptyDatasets = []
        this.totalDatasets = 0
        this.showStatusMessage = false
      })

      this.localBus.$on('dataAverageInfo', () => {
        this.showStatusMessage = true
        if (this.emptyDatasets.length > 0 && this.totalDatasets === this.emptyDatasets.length) {
          this.statusMessage = this.statusMessageEmptyDatasets
        } else if (this.emptyDatasets.length > 0 && this.totalDatasets > this.emptyDatasets.length) {
          this.statusMessage = this.alertAverageInfo + ' <br> ' + this.statusMessageEmptyDatasets
        } else {
          this.statusMessage = this.alertAverageInfo
        }
        this.statusMessageType = 'info'
      })
    },
    mounted () {
      if (this.chartInitialData && store.get('app/historicData').initialDates) {
        this.localBus.$emit('setChartHistoryData', store.get('app/historicData').initialDates)
      }
    },

    destroyed () {
      store.set('app/historicData', {})
    },

  }
</script>
